<template>
  <div class="card" :class="{'is-shadowless': !isModal}">
    <div class="card-content">
      <header class="mb-5">
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <h2 class="is-size-6 has-text-primary has-text-weight-bold mr-3">Sampling</h2>
          <div @click="$emit('close')" v-if="isModal">
            <b-icon class="cursor-pointer" icon="close-thick"/>
          </div>
        </div>
        <div>
          <p class="has-text-secondary mb-3">{{ selectedFarm.name }}</p>
          <p class="is-size-7">*If the pond you are looking for is not found, it means that your pond has not stocked.</p>
        </div>
      </header>
      <section class="my-2">
        <ValidationObserver ref="form">
          <form class="mt-3" @submit.prevent="handleSubmit">
            <div class="columns is-tablet">
              <div class="column is-half-tablet">
                <ValidationProvider :name="$t('farm.block')" rules="required" v-slot="{ errors }">
                  <b-field :label="$t('farm.block')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <sg-select :items="selectedFarm.blocks" type="is-secondary is-light"
                               :disabled="defaultValue ? true : false"
                               label="block_name"
                               v-model="form.block"
                               @change="form.pond = null"
                               :placeholder="`${$t('select')} ${$t('farm.block')}`" expanded/>
                  </b-field>
                </ValidationProvider>
              </div>
              <div class="column is-half-tablet">
                <ValidationProvider :name="$t('farm.pond')" rules="required" v-slot="{ errors }">
                  <b-field :label="$t('farm.pond')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <sg-select :items="filteredPonds" type="is-secondary is-light" v-model="form.pond"
                               :disabled="defaultValue ? true : false"
                               @change="onChangePond"
                               label="name"
                               :placeholder="`${$t('select')} ${$t('farm.pond')}`" expanded/>
                  </b-field>
                </ValidationProvider>
              </div>

              <div class="column is-half-tablet">
                <ValidationProvider :name="$t('date')" rules="required" v-slot="{ errors }">
                  <b-field :label="$t('date')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-datepicker
                        :placeholder="$t('date')"
                        icon="calendar-text"
                        locale="id-ID"
                        @input="handleDoc"
                        v-model="form.date">
                    </b-datepicker>
                  </b-field>
                </ValidationProvider>
              </div>
              <div class="column is-half-tablet">
                <b-field label="DOC">
                  <b-input disabled v-model="doc"/>
                </b-field>
              </div>

              <div class="column is-half-tablet">
                <ValidationProvider name="MBW" rules="required" v-slot="{ errors }">
                  <b-field label="MBW" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input type="number" id="mbw" step="any" v-model.number="form.mbw"
                             class="w-100"
                             placeholder="Ex: 23.37"/>
                    <p class="control">
                      <span class="button is-static">gr</span>
                    </p>
                  </b-field>
                </ValidationProvider>
              </div>

              <div class="column is-half-tablet">
                <ValidationProvider name="SR" rules="required" v-slot="{ errors }">
                  <b-field label="SR" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input type="number" id="sr" step="any" v-model.number="form.sr"
                             class="w-100"
                             placeholder="Ex: 23.37"/>
                    <p class="control">
                      <span class="button is-static">%</span>
                    </p>
                  </b-field>
                </ValidationProvider>
              </div>
            </div>

            <b-collapse :open="false" aria-id="advanced_options">
              <p class="has-text-secondary border-top border-bottom py-3 mb-5 is-flex is-justify-content-space-between"
                 slot="trigger"
                 slot-scope="props"
                 aria-controls="advanced_options">
                <span>Advanced Options</span>
                <b-icon
                    :icon="props.open ? 'menu-up-outline' : 'menu-down-outline'">
                </b-icon>
              </p>
              <div>
                <div class="columns is-tablet">
                  <div class="column is-half-tablet">
                    <ValidationProvider :name="$t('sampling.hollow')" rules="" v-slot="{ errors }">
                      <b-field :label="$t('sampling.hollow')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                        <b-input type="number" id="kropos" step="any" v-model.number="form.kropos"
                                 placeholder="Ex: 52"/>
                      </b-field>
                    </ValidationProvider>
                  </div>
                  <div class="column is-half-tablet">
                    <ValidationProvider :name="$t('sampling.molting')" rules="" v-slot="{ errors }">
                      <b-field :label="$t('sampling.molting')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                        <b-input type="number" id="molting" step="any" v-model.number="form.molting"
                                 placeholder="Ex: 52"/>
                      </b-field>
                    </ValidationProvider>
                  </div>
                  <div class="column is-half-tablet">
                    <ValidationProvider :name="$t('sampling.pale')" rules="" v-slot="{ errors }">
                      <b-field :label="$t('sampling.pale')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                        <b-input type="number" id="warna_pucat" step="any" v-model.number="form.warna_pucat"
                                 placeholder="Ex: 52"/>
                      </b-field>
                    </ValidationProvider>
                  </div>
                  <div class="column is-half-tablet">
                    <ValidationProvider :name="$t('sampling.undersize')" rules="" v-slot="{ errors }">
                      <b-field :label="$t('sampling.undersize')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                        <b-input type="number" id="blantik" step="any" v-model.number="form.blantik"
                                 placeholder="Ex: 52"/>
                      </b-field>
                    </ValidationProvider>
                  </div>
                  <div class="column is-half-tablet">
                    <ValidationProvider :name="$t('sampling.red_leg')" rules="" v-slot="{ errors }">
                      <b-field :label="$t('sampling.red_leg')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                        <b-input type="number" id="kaki_merah" step="any" v-model.number="form.kaki_merah"
                                 placeholder="Ex: 52"/>
                      </b-field>
                    </ValidationProvider>
                  </div>
                  <div class="column is-half-tablet">
                    <ValidationProvider :name="$t('sampling.red_tail')" rules="" v-slot="{ errors }">
                      <b-field :label="$t('sampling.red_tail')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                        <b-input type="number" id="ekor_merah" step="any" v-model.number="form.ekor_merah"
                                 placeholder="Ex: 52"/>
                      </b-field>
                    </ValidationProvider>
                  </div>
                </div>

                <b-field label="Add Picture">
                  <div class="is-flex is-flex-wrap-wrap">
                    <b-upload v-model="form.images" multiple drag-drop accept="image/*"
                              class="sg-uploader mr-4 mb-4">
                      <b-icon icon="image-outline" size="is-large"></b-icon>
                      <b-icon icon="plus" class="add-icon"></b-icon>
                    </b-upload>

                    <div v-for="(image,k) in images" :key="k" class="sg-uploader mr-4 mb-4">
                      <div class="img-overlay" v-if="image.photo">
                        <div @click="deleteFiles(k, image)">
                          <b-icon icon="trash-can-outline" class="remove-icon"/>
                        </div>
                        <img :src="image.photo" alt=""/>
                      </div>
                      <div class="img-overlay" v-else>
                        <div @click="deleteFiles(k)">
                          <b-icon icon="trash-can-outline" class="remove-icon"/>
                        </div>
                        <img :src="image" alt=""/>
                      </div>
                    </div>
                  </div>
                </b-field>
              </div>
            </b-collapse>

          </form>

        </ValidationObserver>
      </section>
      <footer class="has-text-right">
        <b-button type="is-secondary is-light" @click="$emit('close')" v-if="isModal">{{ $t('cancel') }}</b-button>
        <b-button type="is-primary is-gradient" class="ml-2" @click="handleSubmit">{{ $t('save') }}</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import SgSelect from "@/components/Sg/SgSelect";

export default {
  name: "InputSampling",
  components: {SgSelect},
  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    defaultValue: {
      type: Object,
      required: false,
    },
  },
  computed: {
    selectedFarm() {
      return this.$store.state.farm.selectedFarm
    },
    filteredPonds() {
      if (this.defaultValue) return this.selectedFarm.ponds.filter(e => e.pond_id === this.defaultValue.pond_id)
      if (this.form.block && this.selectedFarm.ponds) return this.selectedFarm.ponds.filter(e => e.block_id === this.form.block.block_id && e.status === 2)
      else return []
    },
  },
  data: () => {
    return {
      form: {
        block: null,
        pond: null,
        stock: null,
        images: [],
      },
      images: [],
      delete_images: [],
      doc: '-',
    }
  },
  watch: {
    'form.images': {
      deep: true,
      handler(values) {
        this.images = []
        if (values && values.length) {
          for (let i = 0; i < values.length; i++) {
            const file = values[i];
            this.images.push(typeof file === 'object' && file.photo ? file : URL.createObjectURL(file));
          }
        }
      }
    }
  },
  created() {
    this.resetForm()
  },
  methods: {
    resetForm() {
      if (this.defaultValue) {
        this.form = {
          block: this.selectedFarm.blocks.find(e => e.block_id === this.defaultValue.block_id),
          pond: this.filteredPonds.find(e => e.pond_id === this.defaultValue.pond_id),
          date: new Date(this.defaultValue.sampling_date),
          mbw: this.defaultValue.mbw,
          sr: this.defaultValue.sr,
          num_of_shrimp: this.defaultValue.num_of_shrimp,
          total_weight: this.defaultValue.total_weight,
          kropos: this.defaultValue.kropos,
          warna_pucat: this.defaultValue.warna_pucat,
          molting: this.defaultValue.molting,
          blantik: this.defaultValue.blantik,
          kaki_merah: this.defaultValue.kaki_merah,
          ekor_merah: this.defaultValue.ekor_merah,
          images: this.defaultValue.photos || [],
          stock: null,
        }
        this.onChangePond()
      }
    },

    handleDoc() {
      this.doc = '-'
      if (this.form.stock && this.form.date) this.doc = this.$getDOC(this.form.stock.stock_date, this.form.date)
    },

    async onChangePond() {
      if (this.form.pond && this.form.pond.pond_id) {
        this.$loading()
        this.form.stock = await this.$store.dispatch('farm/getActiveStock', this.form.pond.pond_id)
        this.handleDoc()
        this.$loading(false)
      }
    },

    deleteFiles(index, item = null) {
      if (item) {
        this.$delete(async () => {
          this.delete_images.push(item)
          this.form.images.splice(index, 1)
        }, false, 'Are you sure you want to delete this image?')
      } else this.form.images.splice(index, 1)
    },
    handleSubmit() {
      this.$refs['form'].validate().then(async success => {
        if (success) {
          let pond = this.form.pond

          this.$loading()

          if (this.defaultValue && this.defaultValue.sampling_id) {
            let photos = (await Promise.all(
                this.form.images
                    .filter(e => !e.photo)
                    .map(e => this.$base64(e))
            )).map(e => {
              return {photo: e}
            })

            if (this.delete_images.length) {
              for (let i = 0; i < this.delete_images.length; i++) {
                let delImage = this.delete_images[i]
                await this.$store.dispatch('farm/deleteSamplingPhoto', delImage.photo_id)
              }
            }

            await this.$store.dispatch('farm/updateSampling', {
              stock_id: this.defaultValue.stock_id,
              mbw: this.form.mbw,
              sr: this.form.sr,
              num_of_shrimp: this.form.num_of_shrimp,
              total_weight: this.form.total_weight,
              sampling_date: this.$timestamp(this.form.date),
              kropos: this.form.kropos,
              molting: this.form.molting,
              warna_pucat: this.form.warna_pucat,
              blantik: this.form.blantik,
              kaki_merah: this.form.kaki_merah,
              ekor_merah: this.form.ekor_merah,
              created_at: this.$timestamp(this.form.date),
              photos: photos,
              sampling_id: this.defaultValue.sampling_id
            })
          } else {
            let stock = await this.$store.dispatch('farm/getActiveStock', pond.pond_id)
            if (stock) {
              let photos = (await Promise.all(this.form.images.map(e => this.$base64(e)))).map(e => {
                return {photo: e}
              })

              await this.$store.dispatch('farm/addSampling', {
                stock_id: stock.stock_id,
                num_of_shrimp: this.form.num_of_shrimp,
                total_weight: this.form.total_weight,
                sampling_date: this.$timestamp(this.form.date),
                kropos: this.form.kropos,
                molting: this.form.molting,
                warna_pucat: this.form.warna_pucat,
                blantik: this.form.blantik,
                kaki_merah: this.form.kaki_merah,
                ekor_merah: this.form.ekor_merah,
                created_at: this.$timestamp(this.form.date),
                photos: photos
              })
            }
          }

          this.$loading(false)

          this.form = {}
          this.$emit('close')
          this.$emit('submit')
          this.$store.dispatch('farm/refreshOverview')
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.card {
  width: 720px !important;
  max-width: 100%;
}
</style>

