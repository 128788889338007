<template>
  <div>
    <sg-carousel-modal v-model="photoModal" :images="photos" v-if="!hideAction"/>

    <template v-if="!hideAction">
      <!--
            <b-dropdown ref="dropdown"
                        class="mb-2"
                        position="is-bottom-right"
                        append-to-body
                        aria-role="menu"
                        @active-change="handleDropdown"
            >
              <template #trigger="{ active }">
                <b-button type="is-secondary is-light" role="button">
                  <div class="is-flex is-align-items-center">
                    <span class="excerpt" style="max-width: 300px">{{
                        selectedPonds.length ? filteredPonds.map(e => e.name).join(' • ') : $t('select') + ' ' + $t('farm.pond')
                      }}</span>
                    <b-icon :icon="active ? 'chevron-up' : 'chevron-down'"></b-icon>
                  </div>
                </b-button>
              </template>

              <b-dropdown-item
                  aria-role="menu-item"
                  :focusable="false"
                  custom
                  paddingless>
                <div class="modal-card" style="width:300px;">
                  <section class="modal-card-body pb-2">
                    <div class="is-flex is-flex-direction-column">
                      <div class="columns overflow-y-auto mb-4" style="max-height: 300px">
                        <div class="column is-half" v-for="(item, key) in ponds" :key="key">
                          <b-checkbox v-model="selectedPonds" :native-value="item.pond_id">
                            {{ item.name }}
                          </b-checkbox>
                        </div>
                      </div>
                      <div class="is-flex is-justify-content-end mt-4">
                        <b-button type="is-primary" @click="$refs.dropdown.toggle()">{{ this.$t('select') }}
                          {{ this.$t('farm.pond') }}
                        </b-button>
                      </div>
                    </div>
                  </section>
                </div>
              </b-dropdown-item>
            </b-dropdown>
            <b-button type="is-primary" class="ml-2 mb-2" @click="selectAllPonds">{{ $t('select') }} {{ $t('all') }}
              {{ $t('farm.pond') }}
            </b-button>
            -->
      <div class="is-flex is-justify-content-space-between is-align-items-center is-flex-wrap-wrap">
        <!--
                <div class="btn-tabs mb-3">
                  <b-button :type="filterType === $t('all') ? 'is-primary is-light' : 'is-text'"
                            class="mr-2"
                            @click="filterData($t('all'))">{{ $t('all') }} {{ filterType === $t('all') ? ' Sampling' : '' }}
                  </b-button>
                  <b-button :type="filterType === totalSampling - n + 1 ? 'is-primary is-light' : 'is-text'"
                            class="mr-2"
                            @click="filterData(totalSampling - n + 1)"
                            v-for="n in totalSampling"
                            :key="totalSampling - n + 1">
                    {{ filterType === totalSampling - n + 1 ? 'Sampling ' : '' }}{{ totalSampling - n + 1 }}
                  </b-button>
                </div>
        -->

        <div class="is-flex is-align-items-center mb-3">
          <p class="mr-3">{{ $t('select') }} {{ $t('date') }}</p>
          <sg-select :items="samplingDate"
                     type="is-secondary is-light"
                     v-model="selectedDate"
                     :placeholder="`${$t('select')} ${$t('date')}`"/>
        </div>
        <b-button type="is-primary" tag="router-link" :to="`/farm/${selectedFarm.farm_id}/sampling/add`"
                  class="mb-3 has-shadow is-gradient ml-auto"
                  v-if="canAddSampling">
          {{ $t('add') }} Data
        </b-button>
      </div>
    </template>

    <div>
      <b-table :mobile-cards="false"
               sticky-header :data="filteredSampling"
               detail-key="sampling_id"
               :show-detail-icon="!hideAction" :opened-detailed="detail" detailed>
        <p class="has-text-weight-bold has-text-dark is-size-5 mb-2" v-if="dateLabel">{{ dateLabel }}</p>

        <template #detail="props">
          <div class="is-flex">
            <div>
              <p>{{ $t('sampling.hollow') }}: <span class="has-text-weight-bold">{{ props.row.kropos }}</span></p>
              <p>{{ $t('sampling.molting') }}: <span class="has-text-weight-bold">{{ props.row.molting }}</span></p>
              <p>{{ $t('sampling.pale') }}: <span class="has-text-weight-bold">{{ props.row.warna_pucat }}</span></p>
            </div>
            <div class="ml-6 pl-6">
              <p>{{ $t('sampling.undersize') }}: <span class="has-text-weight-bold">{{ props.row.blantik }}</span></p>
              <p>{{ $t('sampling.red_leg') }}: <span class="has-text-weight-bold">{{ props.row.kaki_merah }}</span></p>
              <p>{{ $t('sampling.red_tail') }}: <span class="has-text-weight-bold">{{ props.row.ekor_merah }}</span></p>
            </div>
          </div>
        </template>

        <template #empty>
          <p class="has-text-centered">{{ $t('no') }} Data</p>
        </template>

        <b-table-column field="pond" :label="$t('farm.pond')" v-slot="props">
          {{ props.row.pond_name }}
        </b-table-column>
        <b-table-column field="sampling_date" :label="$t('date')" v-slot="props">
          {{ $dateFormat(props.row.sampling_date) }}
        </b-table-column>
        <b-table-column field="doc" label="DOC" v-slot="props">
          {{ props.row.stock ? $getDOC(props.row.stock.stock_date, props.row.sampling_date) : '-' }}
        </b-table-column>
        <b-table-column field="mbw" label="MBW" v-slot="props">
          {{ $number(props.row.mbw) }} gr
        </b-table-column>
        <b-table-column field="size" label="Size" v-slot="props">
          {{ props.row.size }}
        </b-table-column>
        <b-table-column field="density" label="Density" v-slot="props">
          {{ $number(props.row.density) }}
        </b-table-column>
        <b-table-column field="sr" label="SR" v-slot="props">
          <div class="whitespace-nowrap">{{ $number(props.row.sr) }}%</div>
        </b-table-column>
        <b-table-column field="biomass" label="Biomass" v-slot="props">
          <div class="whitespace-nowrap">{{ $number(props.row.biomass) }} Kg</div>
        </b-table-column>
        <b-table-column field="fcr" label="FCR" v-slot="props">
          <div class="whitespace-nowrap">{{ $number(props.row.fcr) }}</div>
        </b-table-column>
        <b-table-column field="adg" label="ADG" v-slot="props">
          <div class="whitespace-nowrap">{{ $number(props.row.adg) }} gr</div>
        </b-table-column>
        <b-table-column field="image" :label="$t('image')" v-slot="props" v-if="!hideAction">
          <div class="is-flex image-list" v-if="props.row.photos && props.row.photos.length">
            <div @click="showImageModal(props.row.photos)" v-for="(image, key) in props.row.photos" :key="key">
              <img v-if="image.photo"
                   class="img-sm"
                   :src="image.photo"
                   alt=""/>
            </div>
          </div>
          <span v-else>-</span>
        </b-table-column>

        <b-table-column field="action" label="" v-slot="props" v-if="canEditSampling && !hideAction">
          <div class="is-flex">
            <b-button class="mr-2"
                      type="is-secondary is-light"
                      @click="editSampling(props.row)"
                      icon-left="pencil-outline"></b-button>
            <b-button type="is-danger is-light"
                      @click="deleteSampling(props.row.sampling_id)"
                      icon-left="trash-can-outline"></b-button>
          </div>
        </b-table-column>
      </b-table>

      <!--  Modal Sampling -->

      <b-modal
          v-model="isModalOpen"
          has-modal-card
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-modal>
        <template #default="props">
          <input-sampling is-modal @close="props.close" @submit="getData(true)" :default-value="sampling_form"/>
        </template>
      </b-modal>
    </div>
    <template v-if="!hideChart">
      <div class="my-3 btn-tabs">
        <b-button :type="filterChart === 'MBW' ? 'is-danger is-light' : 'is-text'"
                  class="mr-2"
                  @click="generateChart('MBW')">MBW
        </b-button>
        <b-button :type="filterChart === 'SR' ? 'is-danger is-light' : 'is-text'"
                  class="mr-2"
                  @click="generateChart('SR')">SR
        </b-button>
        <b-button :type="filterChart === 'Biomass' ? 'is-danger is-light' : 'is-text'"
                  class="mr-2"
                  @click="generateChart('Biomass')">Biomass
        </b-button>
        <b-button :type="filterChart === 'FCR' ? 'is-danger is-light' : 'is-text'"
                  class="mr-2"
                  @click="generateChart('FCR')">FCR
        </b-button>
        <b-button :type="filterChart === 'ADG' ? 'is-danger is-light' : 'is-text'"
                  class="mr-2"
                  @click="generateChart('ADG')">ADG
        </b-button>
      </div>
      <div class="my-3">
        <div class="columns is-tablet" v-if="chartData.length">
          <div class="column is-half-tablet" v-for="(chart,key) in chartData" :key="key">
            <div class="card">
              <header class="card-header">
                <p class="card-header-title">
                  {{ chart.pond_name }}
                </p>
              </header>
              <div class="card-content">
                <apexchart type="area" height="350" :options="chart.options" :series="chart.series"></apexchart>
              </div>
            </div>
          </div>
        </div>
        <p class="has-text-centered" v-else>{{ $t('no') }} Data</p>
      </div>
    </template>
  </div>
</template>

<script>
import InputSampling from "@/components/Farm/Sidebar/InputSampling";
import SgCarouselModal from "@/components/Sg/SgCarouselModal";
import VueApexCharts from "vue-apexcharts";
import {mapGetters} from "vuex";
import SgSelect from "@/components/Sg/SgSelect";

export default {
  name: "Sampling",
  components: {
    SgSelect,
    SgCarouselModal, InputSampling, apexchart: VueApexCharts,
  },
  props: {
    selectedBlock: Object,
    hideAction: Boolean,
    hideChart: Boolean,
    dateLabel: String,
    startDate: Number,
  },
  data() {
    return {
      data: [],
      detail: [],

      sampling_form: null,
      isModalOpen: false,

      totalSampling: 0,
      filterType: this.$t('all'),

      filterChart: 'MBW',
      chartData: [],

      photos: [],
      photoModal: false,

      selectedPonds: [],

      oldPonds: [],

      samplingDate: [],
      selectedDate: null,
    }
  },
  computed: {
    ...mapGetters('farm', [
      'canAddSampling',
      'canEditSampling',
      'selectedFarm',
    ]),
    ponds() {
      return this.$store.state.farm.overview.ponds.filter(e => e.block_id === this.selectedBlock.block_id)
    },
    refresh_data() {
      return this.$store.state.farm.overview.refresh_data
    },
    filteredPonds() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.ponds.filter(e => this.selectedPonds.includes(e.pond_id)).sort((a, b) => a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: 'base',
      }))
    },

    pond_ids() {
      let pond_ids = this.$route.query.pond_ids
      if (pond_ids) return pond_ids.split(',').map(e => parseInt(e))
      return []
    },

    filteredSampling() {
      let data = this._.cloneDeep(this.data)
      if (this.pond_ids.length) data = this.data.filter(e => this.pond_ids.includes(e.pond_id))
      if (this.selectedDate && this.selectedDate.value) data = data.filter(e => this.$startDay(e.sampling_date).valueOf() === this.selectedDate.value)
      if (this.startDate && this.endDate) data = data.filter(e => e.sampling_date >= this.startDate && e.sampling_date <= this.endDate)

      return data
    },

    endDate() {
      return this.$moment(this.startDate).add({
        hours: 23,
        minutes: 59,
        seconds: 59,
        milliseconds: 999,
      }).valueOf()
    },
  },
  watch: {
    refresh_data(value) {
      if (value) this.getData(true)
    },
  },
  created() {
    if (this.pond_ids.length) this.selectedPonds = this.pond_ids
    else this.selectedPonds = this._.cloneDeep(this.ponds).map(e => e.pond_id)
    this.getData(true)
  },
  methods: {
    handleDropdown(value) {
      if (value) this.oldPonds = this.filteredPonds
      if (!value) {
        if (JSON.stringify(this.oldPonds) !== JSON.stringify(this.filteredPonds)) this.getData(true)
      }
    },

    showImageModal(photos) {
      this.photos = photos.map(e => e.photo)
      this.photoModal = true
    },

    async getData(refresh = false) {
      this.$loading()
      this.data = []
      for (let i = 0; i < this.filteredPonds.length; i++) {
        let pond = this.filteredPonds[i]
        if (pond.selected_cycle) {
          if (!pond.stock) {
            pond.stock = await this.$store.dispatch('farm/getStockOverview', pond)
          }
          if (refresh || !pond.sampling) {
            pond.sampling = []
            let sampling = await this.$store.dispatch('farm/getSampling', {
              farm_id: pond.farm_id,
              pond_id: pond.pond_id,
              cycle_id: pond.selected_cycle.cycle_id,
            })
            if (!sampling.error && sampling.data && sampling.data.length) {
              pond.sampling = sampling.data.map(e => {
                e.pond_name = pond.name
                e.block_id = pond.block_id
                e.pond_id = pond.pond_id
                e.stock = pond.stock.find(f => f.cycle_id === pond.selected_cycle.cycle_id)
                return e
              })
            }
          }
        }
      }

      this.filterData(this.filterType)

      this.$loading(false)
      this.$store.dispatch('farm/refreshOverview', false)
    },

    filterData(type = this.$t('all')) {
      this.filterType = type
      this.totalSampling = 0
      this.data = this.filteredPonds.reduce((a, b) => {
        if (b.sampling) {
          let totalSampling = b.sampling.length
          if (totalSampling > this.totalSampling) this.totalSampling = totalSampling

          if (this.filterType === this.$t('all')) a = a.concat(b.sampling)
          else if (typeof this.filterType === 'number') {
            let sampling = b.sampling
            if (sampling && sampling[this.filterType - 1]) a.push(sampling[this.filterType - 1])
          }
        }
        return a
      }, []).sort((a, b) => {
        if (a.pond_name !== b.pond_name) {
          return a.pond_name.localeCompare(b.pond_name, undefined, {
            numeric: true,
            sensitivity: 'base',
          })
        }
        return b.created_at > a.created_at ? 1 : -1
      })
      if (typeof this.filterType === 'number' && this.filterType > this.totalSampling) {
        this.filterType = this.totalSampling - 1
        return this.filterData(this.filterType)
      }

      this.samplingDate = this.data.reduce((a, b) => {
        let date = this.$startDay(b.sampling_date).valueOf()
        let find = a.find(e => e.value === date)
        if (!find) a.push({
          label: this.$dateFormat(date),
          value: date,
        })
        return a
      }, []).sort((a, b) => b.value > a.value ? 1 : -1)

      this.samplingDate = [
        {
          label: this.$t('all') + ' Sampling',
          value: null,
        },
        ...this.samplingDate,
      ]

      this.selectedDate = this.samplingDate[0]

      if (!this.hideChart) this.generateChart()

      this.$emit('loaded')
    },

    generateChart(type = 'MBW') {
      this.filterChart = type
      this.chartData = this.filteredPonds.reduce((a, b) => {
        if (b.sampling && b.sampling.length) {
          let stock = b.sampling[0].stock
          let series = [{
            name: this.filterChart,
            data: b.sampling.sort((a, b) => a.sampling_date > b.sampling_date ? 1 : -1).map(e => {
              return {
                x: e.sampling_date,
                y: e[this.filterChart.toLowerCase()]
              }
            })
          }]

          a.push({
            pond_name: b.name,
            series: series,
            options: {
              chart: {
                type: 'area',
                height: 350,
                zoom: {
                  enabled: true
                }
              },
              colors: ['#FF4060', '#3998EB'],
              dataLabels: {
                enabled: false
              },
              stroke: {
                curve: 'smooth'
              },

              xaxis: {
                type: 'datetime',
              },
              legend: {
                horizontalAlign: 'center',
                itemMargin: {
                  horizontal: 5,
                  vertical: 10
                },
              },
              tooltip: {
                x: {
                  formatter: (value) => {
                    return this.$dateFormat(value, 'DD MMM YYYY') + ' - DOC ' + (stock ? this.$getDOC(stock.stock_date, value) : '-')
                  }
                }
              }
            }
          })
        }
        return a
      }, []).sort((a, b) => a.pond_name.localeCompare(b.pond_name, undefined, {
        numeric: true,
        sensitivity: 'base',
      }))
    },

    editSampling(sampling) {
      this.sampling_form = this._.cloneDeep(sampling)
      this.isModalOpen = true
    },
    deleteSampling(id) {
      this.$delete(async () => {
        this.$loading()
        await this.$store.dispatch('farm/deleteSampling', id)
        await this.getData(true)
        this.$loading(false)
      })
    },
  }
}
</script>
